<template>
  <div class="flightformbox_itembox">
    <div class="flightformbox_redio d-flex align-items-center">

      <h4>{{$t(`sabre.composition.${passengerType}`)}} {{inx + 1}}</h4>

      <div class="d-flex checkhide highlight">
        <div class="form-check" v-for="(g, inx) in genderList" :key="inx" :class="{'selected': gender === g}">

          <input class="form-check-input" type="radio"
          :name="`flexRadioDefault-${_uid}`"
          id="flexRadio1" :disabled="disabled"
          :checked="gender === g"
          @click="() => {gender = g;}"/>

          <label class="form-check-label" for="flexRadio1"> {{g.toUpperCase()}} </label>
        </div>
        <div class="form-check" v-if="isShowBabyContain">
          <input class="form-check-input" type="checkbox" :disabled="disabled" v-model="accompaniedByInfant"/>
          <label class="form-check-label" for="flexRadio1">{{ $t('sabre.booking.passenger-box.accompanied-by-infant') }}</label>
        </div>
      </div>

      <div class="flightformarrow" data-bs-toggle="collapse" :href="`#flightform1-${_uid}`" role="button"></div>

    </div>
    <div class="collapse flightformcollapse" :class="{'show': isShow}" :id="`flightform1-${_uid}`">
      <div class="row g-3">
        <div class="col-lg-6">
          <passenger-form-control
            controlType="text"
            v-model="firstName"
            :placeholder="$t('sabre.booking.passenger-box.name')"
            :disabled="disabled"
            :label="$t('sabre.booking.passenger-box.first-name')"
            :invalidFeedback="$t('sabre.booking.passenger-box.invalid-feedback.first-name')"
          />
        </div>
        <div class="col-lg-6">
          <passenger-form-control
            controlType="text"
            v-model="lastName"
            :placeholder="$t('sabre.booking.passenger-box.last-name')"
            :disabled="disabled"
            :label="$t('sabre.booking.passenger-box.last-name')"
            :invalidFeedback="$t('sabre.booking.passenger-box.invalid-feedback.last-name')"
          />
        </div>
        <div class="col-lg-6">
          <passenger-form-control
            controlType="email"
            v-model="email"
            :placeholder="$t('sabre.booking.passenger-box.email')"
            :disabled="disabled"
            :label="$t('sabre.booking.passenger-box.email')"
            :invalidFeedback="$t('sabre.booking.passenger-box.invalid-feedback.email')"
          />
        </div>
        <div class="col-lg-3">
          <passenger-form-control
            controlType="number"
            v-model="mobile"
            :placeholder="$t('sabre.booking.passenger-box.phone-number')"
            :disabled="disabled"
            :label="$t('sabre.booking.passenger-box.phone-number')"
            :invalidFeedback="$t('sabre.booking.passenger-box.invalid-feedback.phone')"
          />
        </div>
        <div class="col-lg-3">
          <passenger-form-control
            controlType="date"
            v-model="dateOfBirth"
            :placeholder="$t('sabre.booking.passenger-box.birthday')"
            :disabled="disabled"
            :label="$t('sabre.booking.passenger-box.birthday')"
            :invalidFeedback="$t('sabre.booking.passenger-box.invalid-feedback.birthday')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'passenger-one-form',
  components: {
    PassengerFormControl: () => import('./formControl'),
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Object,
      default: null,
    },
    passengerType: {
      type: String,
      default: 'adult',
    },
    babiesCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      routerList: 'GET_FO_BOOKING_ROUTERS',
      bookingStateTag: 'GET_FO_BOOKING_STATE',
      initialData: 'GET_SABRE_PASSENGER_DATA',
      condition: 'GET_SABRE_ORDERED_CONDITION',
      isDevelopMode: 'GET_MODE',
    }),
    totalPersonsCount() {
      const { condition } = this;
      const composition = condition?.passengerComposition || null;
      if (!composition) return 0;
      return +composition.adults + +composition.children + +composition.infants + +composition.seniors;
    },
    isShow() {
      // if total less 2, show all and if not, only first
      const { uniqueIndex, totalPersonsCount } = this;
      if (totalPersonsCount <= 2) return true;
      return uniqueIndex < 1;
    },
    isShowBabyContain() {
      const { inx, babiesCount } = this;
      return inx < babiesCount;
    },
  },
  watch: {
    firstName() {
      this.update();
    },
    lastName() {
      this.update();
    },
    email() {
      this.update();
    },
    mobile() {
      this.update();
    },
    dateOfBirth() {
      this.update();
    },
    gender() {
      this.update();
    },
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      dateOfBirth: '',
      genderList: [],
      gender: '',
      inx: 0,
      uniqueIndex: 0,
      accompaniedByInfant: false,
    };
  },
  mounted() {
    this.inx = this.index.inx;
    this.uniqueIndex = this.index.uKey;

    this.genderList = (this.passengerType === 'adult' || this.passengerType === 'senior') ? ['Mr', 'Mrs', 'Miss'] : ['Mr', 'Miss'];
    const sldGdInx = this.inx < 2 ? this.inx : 0;
    this.gender = this.genderList[sldGdInx];

    this.setInitialData();
    if (this.isShowBabyContain) this.accompaniedByInfant = true;
  },
  methods: {
    update() {
      const { passengerType, gender, dateOfBirth, firstName, lastName, email, mobile, accompaniedByInfant } = this;
      this.$emit('update', {
        id: this.uniqueIndex,
        typeIndex: this.inx,
        gender,
        dateOfBirth,
        passengerType,
        firstName,
        lastName,
        email,
        mobile,
        accompaniedByInfant,
      });
    },
    setInitialData() {
      const tempData = this.isDevelopMode ? {
        dateOfBirth: '1999-01-22',
        firstName: 'testa',
        lastName: 'test',
        email: 'test@test.com',
        mobile: '12345789',
        gender: 'Mr',
        accompaniedByInfant: false,
      } : null;
      const { initialData } = this;
      if (!initialData && !tempData) {
        if (this.bookingStateTag !== 0) this.$router.push(`/sabre/${this.routerList[0]}`);
        return;
      }

      this.firstName = tempData?.firstName || initialData[this.uniqueIndex]?.firstName || '';
      this.lastName = tempData?.lastName || initialData[this.uniqueIndex]?.lastName || '';
      this.email = tempData?.email || initialData[this.uniqueIndex]?.email || '';
      this.mobile = tempData?.mobile || initialData[this.uniqueIndex]?.mobile || '';
      this.dateOfBirth = tempData?.dateOfBirth || initialData[this.uniqueIndex]?.dateOfBirth || '';
      this.gender = tempData?.gender || initialData[this.uniqueIndex]?.gender || this.genderList[0];
      this.accompaniedByInfant = tempData?.accompaniedByInfant ?? initialData[this.uniqueIndex]?.accompaniedByInfant ?? false;
    },
  },
};
</script>
